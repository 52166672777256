@use "../base/variable" as *;
@use "../base/theme_orange" as *;
@use "../base/icomoon-style";
@use "../base/mixin" as *;;
@use "../base/keyframes";
.l-footer {
  background-color: $bc_footer;
  &__contents {
    max-width: 1216px;
    width: calc(100% - 40px);
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 3em 0;
    @include media(lg) {
      padding: 40px 20px 10px;
      display: block;
      width: 100%;
    }
  }
  &__column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__logo {
    width: 380px;
    max-width: 100%;    
    @include media(lg) {
      width: auto;
      padding: 0 0 40px;
      max-width: 300px;
      margin: 0 auto;
    }
    @include media(sp) {
      padding: 0 0 20px;
      max-width: 300px;
      margin: 0 auto;
    }
  }
  &__iconsTitle {
    font-size: 1.4rem;
    letter-spacing: .15em;
    margin-bottom: 1.5em;
    @include media(lg) {
      display: none;
    }
  }
  &__icons {
    display: flex;
    flex-direction: column;
    width: 200px;
    @include media(lg) {
      display: block;
      width: auto;
    }
  }
  .snsIcons {
    width: 200px;
    margin-left: 3%;
    @include media(lg) {
      width: auto;
      margin: 0;
    }
    @include media(tab) {
      width: 100%;
    }
    &__list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100px;
      height: 70px;
      font-size: 3.8rem;
      line-height: 1;
      align-items: center;
      @include media(lg) {
        justify-content: center;
        align-items: center;
        width: auto;
        height: auto;
      }
    }
    &__item {
      @include media(lg) {
        margin: .8em .2em .5em;
      }
    }
  }
}
.pageLinks {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  font-size: 1.3rem;
  width: calc(380px + 1em);
  justify-content: flex-start;
  @include media(lg) {
    width: 100%;
    justify-content: space-between;
  }
  @include media(tab){
    justify-content: center;
  }
  &__item {
    margin-right: 56px;
    @include media(lg) {
      margin: .5em;
    }
  }
}
.footerNavi {
  width: 35%;
  margin-left: 3%;
  @include media(lg) {
    display: none;
  }
  &__list {
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    justify-content: stretch;
    font-size: 1.8rem;
    width: 100%;
    // height: 200px;
    border-right: solid 1px $bc_white;
    line-height: 1.2;
  }
  &__item {
    width: 8em;
    margin-bottom: 1em;
  }
}
.copyright {
  letter-spacing: .05em;
  @include media(lg) {
    text-align: center;
  }
}
