@use "../base/variable" as *;
@use "../base/theme_orange" as *;
@use "../base/icomoon-style";
@use "../base/mixin" as *;;
@use "../base/keyframes";

.l-header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  background: $bc_white;
  @include media(tab){
    // position: fixed;
    // width: 100%;
    // z-index: 10;
  }
  + .l-main {
    padding-top: 134px;
    @include media(tab){
      padding-top: 106px;
    }
    @include media(sp){
      padding-top: 79px;
    }
  }
  &__hamburgerbtn {
    display: none;
    width: 32px;
    height: 24px;
    top: 0;
    cursor: pointer;
    text-align: center;
    position: relative;
    margin-left: 2rem;
    @include media (tab) {
      display: block;
      margin-left: 1.4rem;
    }
    span {
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      background-color: $bc_primary;
      -webkit-transition: 0.5s all;
      -moz-transition   : 0.5s all;
      transition        : 0.5s all;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 11px;
      }
      &:nth-child(3) {
        top: 22px;
      }
    }
    &.active span {
      &:nth-child(1) {
        top : 11px;
        left: 0;
        width: 32px;
        background-color: $bc_primary;
        -webkit-transform: rotate(-35deg);
        -moz-transform   : rotate(-35deg);
        transform        : rotate(-35deg);
      }
      &:nth-child(2) {
        left: 60%;
        opacity: 0;
        -webkit-animation: active-hamburger-bar02 .6s forwards;
        animation: active-hamburger-bar02 .6s forwards;
      }
      @-webkit-keyframes active-hamburger-bar02 {
        100% {
          height: 0;
        }
      }
      @keyframes active-hamburger-bar02 {
        100% {
          height: 0;
        }
      }
      &:nth-child(3) {
        top: 11px;
        width: 32px;
        background-color: $bc_primary;
        -webkit-transform: rotate(35deg);
        -moz-transform   : rotate(35deg);
        transform        : rotate(35deg);
      }
    }
  }
  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    padding:10px 20px 10px 50px;
    @include media(tab) {
      padding: 5px 10px;
      min-height: initial;
      font-size: 1.4rem;
      line-height: 1.2;
    }
  }
  &__contact {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    padding-left: 2em;
    span {
      vertical-align: bottom;
      line-height: 2.3rem;
      @include media(tab) {
        display: none;
      }
    }
  }
  .icon-mail {
    margin-right: .3em;
    font-size: 2.3rem;
    vertical-align: bottom;
    line-height: 2.3rem;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 20px 30px 40px;
    background: $bc_white;
    @include media(tab) {
      padding: 20px 10px;
      border-bottom: solid 2px $bc_primary_light;
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    @include media(sp) {
      width: 200px;
    }
    img{
      max-width: 370px;
      width: 100%;
      @include media(lg){
        max-width: 300px;
      }
    }
  }
  &__iconsWrapper {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    .searchForm {
      display: none;
      width: 94%;
      margin: 8px auto;
      margin-left: 1.5em;
      @include media(tab) {
        display: none;
      }
    }
  }
  &__icons {
    display: flex;
    line-height: 1.4;
    @include media(tab) {
      display: none;
      .active & {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 1em 5px;
        border-bottom: solid 1px $bc_primary;
      }
    }
    &.only-pc {
      display: flex;
      @include media(tab) {
        display: none;
      }
    }
    &.only-sp {
      display: none;
      @include media(tab) {
        &.active {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
  .snsIcons {
    position: relative;
    display: flex;
    padding-right: 1.2em;
    color: $bc_icon_gray;
    font-size: 2.8rem;
    @include media(lg) {
      display: none;
    }
    &__item {
      padding-left: 1em;
    }
    &::after {
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      content: "";
      width: 4px;
      height: 100%;
      background: radial-gradient(circle farthest-side, $bc_icon_gray, $bc_icon_gray 50%, transparent 50%, transparent); 
      background-size: 4px 4px;
    }
  }
  .snsIcons.only-sp{
    display: none;
    @include media(lg){
      display: flex;
      width: 100%;
      padding: 0;
      margin: 0;
      align-items: center;
      justify-content: center;
      &:after{
        display: none;
      }
      .snsIcons__item{
        padding: 1.5rem;
      }
    }
  }
  .LCIcons {
    display: flex;
    color: $bc_icon_gray;
    font-size: 2.8rem;
    &__item {
      padding-left: 1em;
      .l-navi__badge{
        font-size: 2rem;
        line-height: 1;
        vertical-align: top;
        padding-top: 1rem;
        display: inline-block;
      }
      @include media(tab) {
        &:nth-child(1) {
          display: none;
        }
      }
    }
    .icon-search {
      color: $bc_icon_gray;
    }
    .icon-triangle_down {
      margin-right: -.8em;
      padding-left: 0.2em;
      font-size: 0.3em;
      vertical-align: middle;
    }
    @include media(tab) {
      width: 100%;
      justify-content: center;
      margin-bottom: 20px;
    }
  }
}
.l-navi {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  color: #fff;
  transition: all .6s;
  @include media(tab) {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100vh;
    padding-bottom: 100px;
    font-size: 1.6rem;
    color: $bc_primary;
    background-color: $bc_primary_light;
    overflow-y: auto;
    z-index: 100;
    &.active {
      visibility: visible;
      opacity: 1;
    }
  }
  &__item {
    position: relative;
    padding: .4% 3% .4% calc(3% + 5px);
    line-height: 1.1;
    @include media(lg) {
      padding: .4% 2% .4% calc(2% + 5px);
    }
    @include media(tab) {
      width: 100%;
      text-align: center;
      padding: 0;
      border-bottom: solid 1px $bc_primary;
    }
    &:nth-of-type(n+3) {
      &::before {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        content: "";
        width: 4px;
        height: 100%;
        background: radial-gradient(circle farthest-side, #fff, #fff 50%, transparent 50%, transparent); 
        background-size: 4px 6px;
        @include media(tab) {
          display: none;
        }
      }
    }
    a {
      display: block;
      padding: .2em 0;
      border-bottom: solid 1px transparent;
      @include media(tab) {
        padding: 1em 5px;
        border-bottom: 0;
      }
    }
    &.is-current,
    &:hover {
      a {
        border-bottom: solid 1px #fff;
        opacity: 1;
        @include media(tab) {
          border-bottom: 0;
        }
      }
    }
    &.sidebarItem {
      display: none;
      @include media(tab) {
        display: block;
      }
    }
  }
}
.searchForm{
  width: 90%;
  margin: auto;
  position: relative;
  &__input[type=search] {
    width: 100%;
    height: 40px;
    border: solid 1px #ccc;
    border-radius: 10px;
    padding-left: 52px;
    @include media (sp) {
      width: 100%;
      margin-bottom: 10px;
    }
    &::placeholder {
      color: #B4B4B4;
      font-family: YuMincho;
    }
  }
  &__button {
    width: 24px;
    height: 24px;
    position: absolute;
    font-size: 2.4rem;
    top: 22%;
    left: 16px;
    color: $bc_icon_gray;
    @include media (sp) {
      top: 16%;
      left: 3%;
    }
  }
}

.no-scroll{
  overflow-y: hidden;
}
 
